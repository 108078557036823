module.exports = {
    displayName: `校聚网`,
    siteUrl: `https://www.xiaoju.ca`, // Site domain. Do not include a trailing slash!
    postsPerPage: 3, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)
    title: `校聚网`,
    // description: `Connect the alumnis together`,
    description: `校聚网 一起来努力连接校友`,
    shortTitle: `xiaoju`, 
    associations: [
        {name:`cquaa`,folderName:`www.cquaa.ca`,url:`https://www.cquaa.ca`, logo:`cquaa.png`, configName:`siteConfig.cquaa.js`, authors:[`john`,`stanley`]},
        {name:`cik`,folderName:`cik`,url:`https://cik.xiaoju.ca`, logo:`cik.png`, configName:`siteConfig.cik.js`, authors:[`cik`]},
        {name:`cqnuaa`,folderName:`cqnuaa`,url:`https://cqnuaa.xiaoju.ca`, logo:`cqnuaa.png`, configName:`siteConfig.cqnu.js`, authors:[`rong`]},
        {name:`nnuca`,folderName:`nnuca`,url:`https://nnuca.xiaoju.ca`, logo:`nnuca.png`, configName:`siteConfig.nnuca.js`, authors:[`lucy`]},
        {name:`jnutaa`,folderName:`jnutaa`,url:`https://jnutaa.xiaoju.ca`, logo:`jnutaa.png`, configName:`siteConfig.jnutaa.js`, authors:[`erica`]},
        {name:`sust`,folderName:`sust`,url:`https://sust.xiaoju.ca`, logo:`sust.png`, configName:`siteConfig.sust.js`, authors:[`xingxing`]},
        {name:`saac`,folderName:`saac`,url:`https://saac.xiaoju.ca`, logo:`saac.png`, configName:`siteConfig.saac.js`, authors:[`nigelzhai`]},
        {name:`bfuaac`,folderName:`bfuaac`,url:`https://bfuaac.xiaoju.ca`, logo:`bfuaac.png`, configName:`siteConfig.bfuaac.js`, authors:[`bfuaac`]},
        {name:`taasdu`,folderName:`taasdu`,url:`https://taasdu.xiaoju.ca`, logo:`taasdu.png`, configName:`siteConfig.taasdu.js`, authors:[`taasdu`]},
        {name:`hzq`,folderName:`hzq`,url:`https://hzq.xiaoju.ca`, logo:`hzq.png`, configName:`siteConfig.hzq.js`, authors:[`hzq`,`steve`]},
        {name:`cara`,folderName:`www.caracanada.com`,url:`https://www.caracanada.com`, logo:`cara.png`, configName:`siteConfig.cara.js`, authors:[`cara`,`charley`,`edward`,`jim`,`robert`,`joe`,`jack`,`gordon`]},
        {name:`ccaba-blog`,folderName:`blog.ccaba.ca`,url:`https://blog.ccaba.ca`, logo:`ccaba-blog.png`, configName:`siteConfig.ccaba-blog.js`, authors:[`jill`]},
        {name:`apatcanada`,folderName:`www.apatcanada.com`,url:`https://www.apatcanada.com`, logo:`apatcanada.png`, configName:`siteConfig.apatcanada.js`, authors:[`apatcanada`]},
        {name:`ispringgala`,folderName:`www.ispringgala.com`,url:`https://www.ispringgala.com`, logo:`ispringgala.png`, configName:`siteConfig.ispringgala.js`, authors:[`ispringgala`]},
    // {name:`njuaa`,folderName:`njuaa`,url:``, logo:``, authors:[`zhao`]},
    ],
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    tags: [`event`,`people`,`about`],
    twitter: ``,
    facebook: ``,
    lang: `en`,
    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
}
