const configFromFile = require(`./utils/siteConfig`)
export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  facebook?: string;
  twitter?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
}

const config: WebsiteConfig = {
  title: configFromFile.title,
  description: configFromFile.description,
  coverImage: 'img/xiaoju-bg-cover.jpg',
  logo: 'img/xiaoju-logo.png',
  lang: 'en',
  siteUrl: configFromFile.siteUrl,
  facebook: configFromFile.facebook,
  twitter: configFromFile.twitter,
  showSubscribe: false,
  mailchimpAction: 'text to be https://twitter.us19.list-manage.com/subscribe/post?u=a89b6987ac248c81b0b7f3a0f&amp;id=7d777b7d75',
  mailchimpName: 'text to be b_a89b6987ac248c81b0b7f3a0f_7d777b7d75',
};

export default config;
